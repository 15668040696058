<template>
  <div>
    <campaignHeader 
      v-if="Object.keys(campaign).length > 0"
      :cover="campaign.cover" 
      :logo="campaign.logo" 
      :name="campaign.name" 
      :is_realty="true"
      :data_left="array_left_header"
      :data_right="array_right_header"
      :badge="{traduction: ['oneCampaigninactive', 'oneCampaignactive'], value: campaign.is_archived}"
      @update_logo_cover="updateLogoCover"
    />
    <b-tabs class="mt-1">
      <b-tab :title="`${$t('proposalscard')} (${proposals_filtered})`" @click="toggleTab('pending')">
        <locations 
          v-if="pending && !loading" 
          :key="change_markers" 
          @toggleMarker="toggleMarker" 
          @searchLocation="searchLocation" 
          @next_page="next_page" 
          :markers="markers_pending" 
          :counter="proposals_filtered" 
          :loader_search="loader_search"
          class="mt-2"
        />
      </b-tab>
      
      <b-tab :title="`${$t('approvedRunning')} (${proposals_approved})`" @click="toggleTab('approve')">
        <locations 
          v-if="!pending && !loading" 
          :key="change_markers" 
          @toggleMarker="toggleMarker" 
          :pending="false" 
          @searchLocation="searchLocation" 
          @next_page="next_page" 
          :markers="markers_selected" 
          :counter="proposals_approved" 
          :loader_search="loader_search" 
          class="mt-2"
          :is_approved="true"
        />
      </b-tab>
    </b-tabs>

  </div>
</template>

<script>
import {
  BTabs, 
  BTab,
} from 'bootstrap-vue'
import locations from '././Index.vue'
import service from "@/services/campaign";
import outofhome_service from '@/services/outofhome'
import { getMotivationalPhrase } from '@/libs/utils/others'
import { getAssetUrl } from '@/libs/utils/urls';
import axios from 'axios'
import config from '@/services/config';
import campaignHeader from '@/views/campaings/campaignHeader.vue';

export default {
  components: {
    locations,
    BTabs,
    BTab,
    campaignHeader
  },
  data() {
    return {
      getAssetUrl,
      getMotivationalPhrase,
      loader_search: false,
      markers_pending: [],
      markers_selected: [],
      change_markers: false,
      proposals: 0,
      proposals_filtered: 0,
      proposals_approved: 0,
      pending: true,
      loading: false,
      cancel_location: null,
      has_next_pending: false,
      has_next_approved: false,
      current_page_pending: 1,
      current_page_approved: 1,
      query: '',
      campaign: {},
      array_left_header: [],
      interval_loading: null
    }
  },
  methods: {
    next_page(pending) {
      if (pending && this.has_next_pending) this.getNextPage(pending);
      else if (this.has_next_approved) this.getNextPage(pending);
    },
    showLoader() {
      this.$vs.loading({
        type: 'sound',
        background: 'rgba(255, 255, 255, 1)',
        text: getMotivationalPhrase(),
      });

      this.interval_loading = setInterval(() => {
        const prev_loading = document.getElementsByClassName('con-vs-loading')[0]

        if (prev_loading) {
          prev_loading.classList.add('fade-out');
          setTimeout(function() {
            prev_loading.remove()
          }, 500);

          this.$vs.loading({
            type: 'sound',
            background: 'rgba(255, 255, 255, 1)',
            text: getMotivationalPhrase(),
          });
        }
      }, 5000);
    },
    hideLoader() {
      clearInterval(this.interval_loading)
      this.$vs.loading.close()
    },
    getNextPage(pending) {
      let current_page = 1
      let status = 'pending'

      if (pending) {
        current_page = this.current_page_pending
      } else {
        current_page = this.current_page_approved
        status = 'approved'
      }

      const filters = {
        campaign: this.$route.params.uuid,
        status,
        query: this.query
      }

      this.showLoader()
      outofhome_service.searchLocations(filters, current_page)
        .then(response => {
          if (pending) {
            const new_pending = this.buildMarkers(response.results)

            new_pending.forEach(marker => {
              this.markers_pending.push(marker)
            })

            this.has_next_pending = response.next !== null;
            this.current_page_pending = response.next ? this.current_page_pending + 1 : this.current_page_pending;
          } else {
            const new_selected = this.buildMarkers(response.results)

            new_selected.forEach(marker => {
              this.markers_selected.push(marker)
            })

            this.has_next_approved = response.next !== null;
            this.current_page_approved = response.next ? this.current_page_approved + 1 : this.current_page_approved;
          }

          this.hideLoader()
        })
    },
    searchLocation(query, pending) {
      this.query = query
      const CancelToken = axios.CancelToken;

      if (this.cancel_location) this.cancel_location()

      let status = 'pending'
      if (pending) {
        this.has_next_pending = false;
        this.current_page_pending = 1;
      } else {
        this.has_next_approved = false;
        this.current_page_approved = 1;
        status = 'approved'
      }

      const filters = {
        campaign: this.$route.params.uuid,
        status,
        query: this.query
      }

      const self = this
      axios({
        method: 'post',
        url: `${config.urlCore}/api/v3/realty/search/locations/`,
        data: filters,
        headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`},
        cancelToken: new CancelToken(function executor(c) {
          self.cancel_location = c;
        })
      })
        .then(response => {
          
          if (pending) {
            self.proposals_filtered = response.data.response.count;
            self.markers_pending = self.buildMarkers(response.data.response.results)
            self.has_next_pending = response.data.response.next !== null;
            self.current_page_pending = response.data.response.next ? self.current_page + 1 : self.current_page;
          } else {
            self.proposals_approved = response.data.response.count;
            self.markers_selected = self.buildMarkers(response.data.response.results)
            self.has_next_approved = response.data.response.next !== null;
            self.current_page_approved = response.data.response.next ? self.current_page + 1 : self.current_page;
          }
          this.loader_search = false;
        })
        .catch(error => {
          if (axios.isCancel(error)) {
            console.log('');
          }
        });
    },
    toggleTab(tab) {
      this.pending = tab === 'pending'
    },
    toggleMarker(markers, approved) {
      this.showLoader()
      outofhome_service.toggleLocation(this.$route.params.uuid, markers)
        .then(() => {
          if (approved) {
            if (this.proposals_filtered > 0) this.proposals_filtered -= markers.length;
            this.proposals_approved += markers.length;
          } else {
            this.proposals_filtered += markers.length;
            if (this.proposals_approved > 0) this.proposals_approved -= markers.length;
          }
          const remove = [];
          markers.forEach(marker => {
            if (approved) {
              this.markers_pending.forEach((marker_pending, index) => {
                if (marker_pending.id === marker) {
                  remove.push(marker_pending)
                  marker_pending.selected = true;
                  marker_pending.status = 'approved';
                  this.markers_pending.splice(index, 1); 
                }
              })

            } else {
              this.markers_selected.forEach((marker_selected, index) => {
                if (marker_selected.id === marker) {
                  remove.push(marker_selected)
                  marker_selected.selected = false;
                  marker_selected.status = null;
                  this.markers_selected.splice(index, 1); 
                }
              })
            }
          })

          if (approved) {
            remove.forEach(marker => {
              this.markers_selected.push(marker)
            })
          } else {
            remove.forEach(marker => {
              this.markers_pending.push(marker)
            })
          }
          this.hideLoader()
          if (this.markers_pending.length < 1) {
            this.next_page(approved)
          } else  if (this.markers_selected.length < 1) {
            this.next_page(approved)
          }

          this.showMessage(approved)
        })
    },
    getImage(marker) {
      if (marker.internal_image) return getAssetUrl(marker.internal_image)
      return marker.external_image
    },
    buildMarkers(data) {
      const arr = []

      data.forEach(marker => {
        const obj = {}
        obj.id = marker.uuid
        obj.external_id = marker.external_id
        obj.selected = marker.selected
        obj.status = marker.status
        obj.position = {lat: marker.lat, lng: marker.lon}
        obj.tooltip = marker.address ? marker.address : marker.name
        obj.img = this.getImage(marker)
        obj.category = marker.category
        obj.state = marker.state
        obj.size = marker.size
        obj.type = marker.type
        obj.format = marker.format
        obj.sub_format = marker.sub_format
        obj.availability = marker.availability

        arr.push(obj)
      })

      return arr
    },
    showMessage(approved) {
      const title = approved ? this.$t('addSpace') : this.$t('removeSpace')
      const text = approved ? 'Se han aprobado los espacios' : 'Se han removido los espacios'
      this.$swal({
        title,
        text,
        icon: 'success',
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: 'btn btn-primary'
        },
        buttonsStyling: false,
        showCancelButton: false,
      })
    },
    getMarkersPending() {
      const filters = {
        campaign: this.$route.params.uuid,
        status: 'pending',
        query: this.query
      }

      outofhome_service.searchLocations(filters, this.current_page_pending)
        .then(response => {
          this.markers_pending = this.buildMarkers(response.results)
          this.proposals_filtered = response.count;
          this.has_next_pending = response.next !== null;
          this.current_page_pending = response.next ? this.current_page_pending + 1 : this.current_page_pending;
          this.hideLoader()
          this.loading = false
          this.getMarkersApproved()
        })
    },
    getMarkersApproved() {
      const filters = {
        campaign: this.$route.params.uuid,
        status: 'approved',
        query: this.query
      }
      outofhome_service.searchLocations(filters, this.current_page_approved)
        .then(response => {
          this.markers_selected = this.buildMarkers(response.results)
          this.proposals_approved = response.count;
          this.has_next_approved = response.next !== null;
          this.current_page_approved = response.next ? this.current_page_approved + 1 : this.current_page_approved;
          this.hideLoader()
          this.loading = false
        })
    },
    fillHeaderData() {
      const array_left = [
        {traduction : 'locationsProposal', value: this.campaign.stats_locations.locations, key: 'proposals'},
        {traduction : 'locationsApproved', value: this.campaign.stats_locations.approved, key: 'approved'},
      ]
      const array_right = [
        {traduction : 'locationsRunning', value: this.campaign.stats_locations.locations, key: 'running'},
        {traduction : 'campaigns.budget', value: this.campaign.budget, key: 'budget'},
      ]
      this.array_right_header = array_right;
      this.array_left_header = array_left;
    },
    updateLogoCover(data) {
      this.campaign.logo = data.logo;
      this.campaign.cover = data.cover;
    },
  },
  created() {
    this.showLoader()
    this.loading = true
    this.getMarkersPending()
    service.getCampaign(this.$route.params.uuid)
      .then(response => {
        this.campaign = response;
        this.fillHeaderData();
      })
  },
}
</script>

<style lang="scss">
</style>
